import React from "react";
import "../styles/footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <section className="disclaimer">
          Articles are rendered from the New York Times Most Popular API. By no
          means I intend to make these articles my own or imply that I have a
          partnership with the New York Times. If somebody wants to read an
          article, they will be redirected to the New York Times Website.
          <hr />
          Created by Cristhian David Soria
        </section>
      </div>
    );
  }
}

export default Footer;
