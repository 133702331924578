import axios from "axios";

export const getArticlesBySection = section => {
  const topStoriesApiUrl = `https://api.nytimes.com/svc/topstories/v2/${section}.json`;
  return axios.get(topStoriesApiUrl, {
    params: {
      "api-key": "r1m7d6jGmXqJQXlACux1nvqS7srO2JPk"
    }
  });
};

export const articleSearch = keyword => {
  const searchUrl = `https://api.nytimes.com/svc/search/v2/articlesearch.json`;
  return axios.get(searchUrl, {
    params: {
      q: keyword,
      "api-key": "2Sp5CHM0v07zy0t7veRm6wCkdpijwSAO"
    },
    crossdomain: true
  });
};
