import React, { Component } from "react";
import "./App.css";
import Banner from "./components/Banner";
import PopularArticles from "./components/PopularArticles";
import Footer from "./components/Footer";

class App extends Component {
  state = {
    section: `national`,
    displayResults: null
  };

  componentDidMount() {
    document.title = "News this Week";
  }
  render() {
    return (
      <div>
        <Banner />
        <PopularArticles state={this.state} />
        <Footer />
      </div>
    );
  }
}

export default App;
