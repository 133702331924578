import React from "react";
import "../styles/optionsBar.css";

class OptionsBar extends React.Component {
  componentDidMount() {
    const input = document.getElementById("searchInput");
    input.addEventListener("keydown", evt => {
      if (evt.keyCode === 13) this.searchArticles();
    });
  }

  getSportsArticles = () => {
    this.props.getArticles("sports");
  };

  getFashionArticles = () => {
    this.props.getArticles("fashion");
  };

  getArtsArticles = () => {
    this.props.getArticles("arts");
  };

  getNationalArticles = () => {
    this.props.getArticles("national");
  };

  getTechArticles = () => {
    this.props.getArticles("technology");
  };

  searchArticles = () => {
    let keyword = document.getElementById("searchInput").value;
    if (keyword.length === 0) return;
    this.props.searchArticles(keyword);
  };

  render() {
    return (
      <div className="options_bar">
        <div className="search_area">
          <input
            id="searchInput"
            type="text"
            placeholder="Find more articles..."
            disabled={false}
          />
          <button
            className="search_btn"
            disabled={false}
            onClick={this.searchArticles}
            type="submit"
          >
            Search
          </button>
          <button className="filter_btn" onClick={this.getNationalArticles}>
            National
          </button>
          <button className="filter_btn" onClick={this.getSportsArticles}>
            Sports
          </button>
          <button className="filter_btn" onClick={this.getFashionArticles}>
            Fashion
          </button>
          <button className="filter_btn" onClick={this.getTechArticles}>
            Technology
          </button>
          <button
            className="filter_btn search_end"
            onClick={this.getArtsArticles}
          >
            Art
          </button>
        </div>
      </div>
    );
  }
}

export default OptionsBar;
