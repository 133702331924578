import React from "react";
import "../styles/card.css";
class ArticleCard extends React.Component {
  altPng =
    "https://upload.wikimedia.org/wikipedia/commons/4/40/New_York_Times_logo_variation.jpg";

  render() {
    const article = this.props.article;
    return (
      <div>
        <article className="card">
          <header className="card__title">
            <h3>{article.title || article.headline.main}</h3>
          </header>
          <figure className="card__thumbnail">
            <img
              src={
                (article.multimedia && article.multimedia[4]) ? article.multimedia[4].url : this.altPng
              }
              alt="Article Thumbnail"
            />
          </figure>
          <main className="card__description">
            {article.abstract || article.snippet}
          </main>
          <a
            href={article.url || article.web_url}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read
          </a>
        </article>
      </div>
    );
  }
}
export default ArticleCard;
