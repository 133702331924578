import React from 'react';

class Banner extends React.Component{
    render() {
        return (
            <div className="top-container">
                <div className="main-banner">
                    The News
                <span className="bottom-banner"> This Week!</span>
                </div>
            </div>
        )
    }
}

export default Banner;