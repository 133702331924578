import React from "react";
import { ClipLoader } from "react-spinners";
import ArticleCard from "./ArticleCard";
import { getArticlesBySection, articleSearch } from "../services/ajaxService";
import OptionsBar from "./OptionsBar";

class PopularArticles extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.getArticles(this.props.state.section);
  }

  cards = null;

  getArticles = section => {
    this.setState({ displayResults: null, loading: true });
    getArticlesBySection(section)
      .then(res => {
        this.setState({ displayResults: res.data.results });
        this.cards = this.state.displayResults.map(each => {
          return <ArticleCard key={each.short_url} article={each} />;
        });
        this.setState({ state: this.state, loading: false }); // updating state to render components
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  searchArticles = keyword => {
    this.setState({ displayResults: null, loading: true });

    articleSearch(keyword)
      .then(res => {
        let results = res.data.response.docs;
        this.setState({ displayResults: results });
        this.cards = this.state.displayResults.map(each => {
          if (each.multimedia.length > 4) {
            each.multimedia[4].url = `https://static01.nyt.com/${
              each.multimedia[4].url
            }`;
          } else {
            each.multimedia = [];
          }
          return <ArticleCard key={each.web_url} article={each} />;
        });
        this.setState({ state: this.state, loading: false });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <div className="spinner">
          <ClipLoader
            sizeUnit={"px"}
            size={400}
            color={"white"}
            loading={this.state.loading}
          />
        </div>
        <OptionsBar
          getArticles={this.getArticles}
          searchArticles={this.searchArticles}
        />
        {!this.state.loading && (
          <section className="card-container">{this.cards}</section>
        )}
      </div>
    );
  }
}

export default PopularArticles;
